// Here you can add other styles
.modal-warning .modal-header {
    background-color: #ffd711 !important;
    color: #000;
}

.rounded-input-field {
    border-radius: 5rem;
}

.media-center {
    visibility: visible;
}

.media-fit-content {
    visibility: visible;
}

.jumbotron {
    background-color: transparent !important;
}

.btn-group-form {
    white-space: nowrap;

    Button {
        width: auto !important;
        min-width: 135px !important;
    }
}

.modal-title {
    font-weight: normal !important;
}

.text-capitalize {
    text-transform: capitalize;
}

.welcome {
    height: 60px;
}

.welcome-centered {
    align-content: center;
    text-align: center;
}

.center-content {
    text-align: center;
    align-items: center;
    justify-content: center;
}

.btn-forms {
    margin: 0.5rem !important;
    height: auto;
    width: 135px;
    float: right;
}

.css-1pahdxg-control,
.css-1pahdxg-control:hover {
    max-height: 35px !important;
    height: 35px !important;
    border-color: lightblue !important;
    box-shadow: 0 0 0 3px lightblue !important;
}

.css-2b097c-container {
    height: 35px;
}

.btn_minus {
    margin-left: 7px;
}

.pagination {
    justify-content: center;
}

.text-area {
    width: 100%;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
}

.no-horizontal-scroll {
    overflow-x: hidden;
}

.horizontal-margin {
    margin-left: 3px;
    margin-right: 3px;
}

.selected-div {
    // position: absolute;
    background-color: #3674af;
    height: 100% !important;
    width: 100% !important;
    // padding: 0 0 !important;
}

.disable-cell {
    background-color: #e4e7ea;
}

.unselected-div {
    // position: absolute;
    background-color: white;
    height: 100%;
    width: 100%;
    // padding: 0 0 !important;
}

.celda-sin-padding {
    // padding: 0 0 !important;
    border-collapse: collapse !important;
    border: none;
}

.small {
    .table-responsive {
        height: 277px;
    }
}

.welcome-small {
    .table-responsive {
        height: 376px;
    }
}

.scheduler-height {
    .table-responsive {
        height: 40rem;
    }
}

.neutral-img {
    height: 100px;
    width: 100px;
    margin: auto;
}

.actived-img {
    height: 100px;
    width: 100px;
    margin: auto;
}

.neutral-img:hover {
    opacity: 0;
    cursor: pointer;
}

.rate-header {
    height: auto;
    text-align: center;
    // background-color: #ffd711;
    border-radius: 7px;
    // border-bottom: 2px #ffd711;
}

.section-title-border-bottom {
    background-color: gray;
    width: 100px;
    height: 2px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5px;
    margin-bottom: 25px;
    opacity: 40%;
}

.section-title-border-top {
    background-color: gray;
    width: 60%;
    height: 2px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5px;
    margin-bottom: 25px;
}

.card-rate-class {
    background-color: white;
    padding: 2rem;
    margin-bottom: 20px;
    border-radius: 20px;
    width: 80%;

}

.rate-container {
    background-color: #e4e5e6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modal-body {
    .section-title-border-top:first-child {
        background-color: gray;
    }
}

#rate-container-out {
    transition: transform 2s;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
}


.card-calendar-content {
    padding: 4px 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.new-table {
    border-radius: 30px !important;
    margin: 0 auto !important;
    text-align: center !important;
    width: 100% !important;
    border-spacing: 100px !important;
    border-collapse: collapse !important;
    padding: 0px !important;

    .new-tr {
        text-align: center !important;

        .new-td {
            // padding: 10px;
            text-align: center !important;
            padding: 0px;
        }
    }
}

// .half-a-border-on-top {
//     border-top:1px solid #c8ced3 !important;
//     // border-top:1px solid white !important;
//     position: relative;
//   }
// .half-a-border-on-top:after {
//     padding:0;margin:0;display:block;/* probably not really needed? */
//     content: "";
//     width:90%;
//     height:2px;
//     background-color:white;
//     position: absolute !important;
//     right:1;
//     top:-2px;
//   }

.div-label {
    position: relative;
    min-width: 70px;
    // background-color: transparent;
    // border: coral solid;
}

.new-td:first-child {
    border-top: none;
    border-bottom: none;
    position: relative;
    // bottom: -16px;
    top: -16px;
    // border: chartreuse solid;
}

.new-th:first-child {
    border: none;
}

.label-schedule {
    position: absolute;
    top: -6px;
    // bottom: -6px;
    right: 15px;
    font-size: 12px;
}


//   .table-responsive-teacher-welcome {
//     overflow-x: hidden;
// }

.label-schedule::after {
    padding: 0;
    margin: 0;
    display: block;
    /* probably not really needed? */
    content: "";
    width: 10px;
    height: 1px;
    background: #c8ced3;
    position: absolute;
    top: 10px;
    // bottom: 10px;
    right: -15px;
}


.table td {
    padding: 0.75rem;
    vertical-align: middle;
    text-align: center;
    border-top: 1px solid #c8ced3;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 120px;
    border-left: none;
}

th {
    text-align: center;
}

.table thead th {
    border-top: 0 !important;
    padding: 0.75rem;
    vertical-align: middle;
    text-align: center;
    // border-top: 1px solid #c8ced3;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.nowrap-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.table {
    width: 100% !important;
    table-layout: auto;
}

.new-th {
    padding: 0px !important;
}

.new-div {
    cursor: pointer;
}

.no-selectable-button {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

button {
    border: black;
    // border-radius: 30px !important;
    transition: all .3s ease-in-out !important;
}

.steps-scheduler {
    padding-bottom: 15px;
    // font-weight: bold;
}

.text-red {
    color: red;
    font-size: smaller;
}

.currently-class {
    color: green;
    font-weight: bold;
}

//CSS de los Select
.css-yk16xz-control {
    border-color: #e4e7ea !important;
    border-radius: 0.25rem !important;
    min-height: 10px !important;
    height: 35px !important;
}

// .col-4 {
//     flex: 0 0 33.33333%;
//     max-width: 33.33333%;
// }

.vertical-padding {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.td-buttons {
    text-align: center;
    display: inline-flex;
    // margin: auto;
}

#listening,
#speaking,
#writing,
#reading {
    resize: none !important;
    width: -webkit-fill-available !important;
    height: 10rem !important;
    outline: none;
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
    background-color: #ffffff !important;
}

.form-control.is-invalid {
    background-image: none;
}

.css-1hwfws3 {
    padding: 0rem 0rem !important;

}

.form-control-selects {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    /* padding: 0.375rem 0.75rem; */
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-selects.is-invalid {
    background-image: none;
    background-position: right calc(0.375em + 0.1875rem) center;
    border-color: #f86c6b;
    // padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    height: auto;
}

.invalid-feedback-selects {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f86c6b;
}

.colored-container {
    padding: 0.5rem;
    margin: 0.5rem;
    opacity: 1;
    border-radius: 10px;

    -webkit-transition: 0.6s;
    -moz-transition: 0.6s;
    -ms-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;

    -webkit-transition: background-color 0.6s linear;
    -moz-transition: background-color 0.6s linear;
    -ms-transition: background-color 0.6s linear;
    -o-transition: background-color 0.6s linear;
    transition: background-color 0.6s linear;

}

.resume-card {
    border: solid 1px gray;
    padding: 0.75rem;
    border-radius: 10px;
}

.resume-card-col {
    border-bottom: solid 1px gray;
    margin-bottom: 5px;
}

// .resume-card-col:last-child {
//     border-bottom: 0;
//     margin-bottom: 0;
// }

.resume-card:hover {
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.50);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.50);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.50);
}

.label-bold {
    font-weight: bold;
}

.colored-container:hover {
    background-color: #f0f3f5;
    opacity: 1;
}

ol.horizontal {
    list-style-type: decimal;
    // width: 600px;
}

ol.horizontal {
    li:first-child {
        float: left;
    }

    li:not(:first-child) {
        float: left;
        width: fit-content;
        margin-left: 5%;
        padding: 2px 0px;
    }
}

.overlay-container:hover {
    display: block;
    position: relative;

}

.contents-card {
    border: solid 1px grey;
    padding: 0.75rem;
    border-radius: 10px;

    .contents-card-header {
        display: flex;
        font-size: 1rem;
        justify-content: center;
    }

    ol {
        counter-reset: li;
        /* Initiate a counter */
        list-style: none;
        /* Remove default numbering */
        *list-style: decimal;
        /* Keep using default numbering for IE6/7 */
        font: 15px 'trebuchet MS', 'lucida sans';
        // padding: 0;
        margin-bottom: 4em;
        text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
    }

    ol ol {
        margin: 0 0 0 2em;
        /* Add some left margin for inner lists */
    }

    li {
        // border-bottom: 1px solid grey;
        // margin-bottom: 5px;
        font-size: 1rem;
    }
}


.rounded-list a {
    position: relative;
    display: block;
    padding: .4em .4em .4em 2em;
    *padding: .4em;
    margin: .5em 0;
    background: #ddd;
    color: #444;
    text-decoration: none;
    border-radius: .3em;
    transition: all .3s ease-out;
}

.rounded-list a:hover {
    background: #eee;
}

.rounded-list a:hover:before {
    transform: rotate(360deg);
}

.rounded-list a:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: -1.3em;
    top: 50%;
    margin-top: -1.3em;
    background: #87ceeb;
    height: 2.5em;
    width: 2.5em;
    line-height: 2em;
    border: .3em solid #ddd;
    text-align: center;
    font-weight: bold;
    border-radius: 2em;
    transition: all .3s ease-out;
}



.class-box-container {
    .class-box-dnd {
        position: relative;
        height: 12rem;
        width: 12rem;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
        color: white;
        padding: 1rem;
        text-align: center;
        font-size: 1rem;
        line-height: normal;
        float: left;
        border: 2px solid transparent;
        border-radius: 4px;
    }

    .class-contents-ul {
        padding: 0;

        li {
            margin: 10px 0 0 0;
            cursor: pointer;
            color: black;
            background-color: rgb(230, 230, 230);
            border-radius: 10px;
            padding-right: 15px;
            padding-left: 15px;
            display: flex;
            justify-content: space-between;

            Button:hover {
                background-color: darkgray;
            }

            .li-button {
                font-size: 1rem;
                padding-right: 10px;
                border-right: 1px dashed black;
                margin-right: 10px;
            }

            a {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .class-box-length {
        z-index: 9;
        position: relative;
        width: 12rem;
        height: 12rem;
        font-size: xxx-large;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        // background-color: darkgrey;
        margin-left: -1rem;
        margin-top: -2rem;
    }
}

.row {
    margin: 0rem;
}

.group-card {
    cursor: pointer;
    transition: box-shadow 0.3s ease-out;
}

.group-card:focus {
    // transition: box-shadow 0.3s ease-in-out;
    outline: 1px solid blue;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
}

.group-card:hover {
    transition: box-shadow 0.3s ease-in;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
}

.modal-separator {
    border-top: 1px solid #c8ced3;
    padding-top: 20px;
}

.row-label-button {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.button-change-state {
    // display: flex;
    // justify-content: center;
    float: right;
}

.change-state-content {
    position: relative;

}

.change-state-message {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(236, 236, 236, 0.9);
    // opacity: 0.5;
    font-size: large;
}

.profile-image-div-container {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-height: initial;
        max-width: inherit;
        width: 150px;
        // height: 150px;
        border: 2px dashed;
        border-radius: 10px;
        z-index: 1;
    }
}

.profile-image-div {
    display: block;
    background-image: url("https://image.flaticon.com/icons/png/512/10/10003.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
    height: 150px;
    width: 150px;
    border: 2px dashed silver;
    border-radius: 10px;
    padding: 2rem;
    position: absolute;
}

.profile-image-upload {

    // position: absolute;
    .uploadPicturesWrapper {
        position: absolute !important;
        bottom: 50px !important; // 286
        width: 130% !important;
    }

    .fileContainer {
        // max-height: 100px;
        // top: 120px;
    }
}

.alert-user-hours {
    font-size: 15px;
    text-align: center;
    width: 100%;
    font-weight: bold;
}

.profile-image-sidebar {
    display: flex !important;
    flex-flow: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;

    img {
        // width: 35px;
        // height: 35px;
        // flex: 1 auto;
        // padding: 1em;
        flex-grow: 1;
        width: 30%;
        cursor: pointer;
    }

    a {
        flex-grow: 2;
        padding-left: 10px;
        width: 50%;
        cursor: pointer;
    }

    border-bottom: 2px solid #3a4248;
}


/* The container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    // font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.class-state-badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    cursor: pointer;

    background-color: transparent;
    color: #20a8d8;
    border: 1px solid #20a8d8;

}

.class-state-badge-activated {
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    background-color: #b3bbc2;
    color: black;
    transform: translateY(4px);
    /* Moving button 4px to y-axis */
}

/* Create a custom checkbox */
.checkmark-teacher {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 10px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark-teacher {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark-teacher {
    background-color: #2196F3;
}

/* Create the checkmark-teacher/indicator (hidden when not checked) */
.checkmark-teacher:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark-teacher when checked */
.checkbox-container input:checked~.checkmark-teacher:after {
    display: block;
}

/* Style the checkmark-teacher/indicator */
.checkbox-container .checkmark-teacher:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.student-tag {
    color: white;
    border-radius: 50%;
    padding: 1px 2px;
    max-inline-size: fit-content;
    font-weight: bold;
}

.cleaned-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.card-default:hover {
    -webkit-box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.75);
}

.resume-classes-message {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 16px;
    color: black
}

.resume-classes-number {
    border-radius: 1rem;
    padding: 0;
    text-align: center
}

.resume-classes-label {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 16px;
    color: white;
}

.table-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.unselected-div:hover {
    background-color: #8fbbe4;
}

// .scheduler-row:hover {
//     background-color: #8fbbe4;
// }


// .profile-image-sidebar > * {
//     flex: 0 0 auto;
//   }

// .css-yk16xz-control:hover {
//     border: 2px solid green !important;
// }
//Darkmode Color
// .modal-content {
//     background-color: #3a4149;
// }

.col-no-padding {
    padding: 0;
}

.label-no-margin-bottom {
    margin-bottom: 0;
}

.log-row {
    margin-bottom: 10px;
    border-radius: 5px;
}

.log-row:hover {
    background-color: #dbeeff;
}

.alert-no-margin {
    margin: 0;
}

.finished-class-log-div {
    margin: 0;
    padding: 0.75rem 1.25rem;
    position: relative;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #28623c;
    background-color: #dbf2e3;
    border-color: #cdedd8;
}

.penalized-class-log-div {
    margin: 0;
    padding: 0.75rem 1.25rem;
    position: relative;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #813838;
    background-color: #fee2e1;
    border-color: #fdd6d6;
}

.canceled-class-log-div {
    margin: 0;
    padding: 0.75rem 1.25rem;
    position: relative;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #813838;
    background-color: #fee2e1;
    border-color: #fdd6d6;
}

.info-log-div {
    margin: 0;
    padding: 0.75rem 1.25rem;
    position: relative;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #336573;
    background-color: #e0f3f8;
    border-color: #d3eef6;
}

.issue-description {
    min-height: 300px;
    resize: none;
}

.issue-description:disabled {
    background-color: #fff;
}

.response-description {
    min-height: 150px;
    resize: none;
}


.response-description:disabled {
    background-color: #fff;
}

.full-center {
    text-align: -webkit-center;
    text-align: -moz-center;
}

.delete-comment {
    font-weight: bold;
    cursor: pointer;
    transition: all ease-in 0.2s;
    color: rgb(209, 57, 57);
    border: solid 1px rgb(209, 57, 57);
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.delete-comment:active,
.delete-comment:focus,
.delete-comment:hover {
    color: white;
    background-color: rgb(209, 57, 57);
}

.save-comment-button {
    color: #63c2de;
    font-weight: bold;
    background-color: transparent;
}

.save-comment-button:hover {
    color: white;
}

.comment-container {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    background: white;
    border-radius: 20px;
    padding: 2rem;
}

.comment-container:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.active-permission {
    background-color: #4dbd74;
    color: white;
    font-weight: bold;
}