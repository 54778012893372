@media screen and (max-width: "480px") {
    /* For mobile phones: */

    .media-center {
        justify-content: center;
    }

    .vertical-margin-col {
        margin: 15px 0 15px 0;
    }

    .media-fit-content {
        max-width: fit-content;
    }

    .progress-container {
        margin: 1px 10px 1px 10px;
    }

    .neutral-img {
        height: 100px;
        width: 100px;
        margin: auto;
    }

    .actived-img {
        height: 100px;
        width: 100px;
        margin: auto;
    }

    .col-no-flex {
        flex: 0 !important;
        padding: 0 3px !important;
    }

    .neutral-img:hover {
        opacity: 0;
        cursor: pointer;
    }

    h1 {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }

    // .col {
    //     text-align: center;
    //     max-width: 100%;
    //     flex: none;
    //     padding: 0 3px !important;
    // }

    .normal-col {
        position: relative;
        width: 100%;
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    // .col-8 {
    //     max-width: 100%;
    //     flex: none;
    //     padding: 0 3px !important;
    // }

    // .col-4 {
    //     max-width: 100%;
    //     flex: none;
    //     padding: 0 3px !important;
    // }

    // .col-5 {
    //     max-width: 100%;
    //     flex: none;
    //     padding: 0 3px !important;
    // }

    // .col-6 {
    //     flex: 0 0 100%;
    //     max-width: 100%;
    //     padding: 0 3px !important;
    // }

    .col-7 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 3px !important;
    }

    // .col-12 {
    //     flex: none;
    //     max-width: 100%;
    //     padding: 0 3px !important;
    // }

    .card-body {
        // padding: 0rem !important;
        text-align: center;
    }

    .form-group {
        text-align: center;
        width: auto !important;
    }

    // .row {
    //     display: contents;
    // }


    .my-flex-row {
        display: flex !important;
        margin-left: auto;
    }

    .col-auto {
        padding-bottom: 5px !important;
        text-align: center;
    }

    label {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }

    .main .container-fluid {
        padding: 0 0 !important;
    }

    .col-xl-12 {
        flex: none;
        max-width: 100%;
        padding: 0 3px !important;
    }

    // .col-sm-2 {
    //     position: relative;
    //     width: 100%;
    //     padding-right: 15px;
    //     padding-left: 0px;
    // }
}